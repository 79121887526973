import { lazy } from 'react';
import React, { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

function lazyLoad(Comp: React.LazyExoticComponent<any>): React.ReactNode {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      }
    >
      <Comp />
    </Suspense>
  );
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: lazyLoad(lazy(() => import(/* webpackPrefetch: true */ '@/pages/Home/Index'))),
    // element: lazyLoad(lazy(() => import(/* webpackPrefetch: true */ '@/pages/Home/Index'))),
  },
  // {
  //   path: 'counter',
  //   element: lazyLoad(lazy(() => import(/* webpackPrefetch: true */ '@/pages/Counter/Index'))),
  // },
];

export default routes;
