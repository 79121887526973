/* eslint-disable camelcase */
import axios from 'axios';
import type {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
} from 'axios';

export interface MyResponseType<T = any> {
  success: boolean;
  ret_code?: number | string;
  code: number | string;
  data: T;
}

const service: AxiosInstance = axios.create({
  baseURL: '/api',
  timeout: 30000,
  headers: {
    Authorization: '',
  },
});

service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => {
    console.error('请求被拦截了～');
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log('response', response);
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error('response error'));
    }
  },
  (error: AxiosError) => {
    // 处理 HTTP 网络错误
    let message = '';
    // HTTP 状态码
    const status = error.response?.status;
    switch (status) {
      case 401:
        message = 'token 失效，请重新登录';
        // 这里可以触发退出的 action
        break;
      case 403:
        message = '拒绝访问';
        break;
      case 404:
        message = '请求地址错误';
        break;
      case 500:
        message = '服务器故障';
        break;
      default:
        message = '网络连接故障';
    }
    console.error('错误了啊', message);
    return Promise.reject(error);
  }
);

export interface Test {
  code: any;
}
const request = async <T = any>(config: AxiosRequestConfig): Promise<MyResponseType<T>> => {
  try {
    const res = await service.request<any, MyResponseType<T>>(config);
    const { data, code } = res;
    return {
      success: true,
      ret_code: code || 0,
      data: data,
      code: 0,
    };
  } catch (err) {
    return {
      success: false,
      code: -1,
      data: null as any,
    };
  }
};

export default request;
