import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMovieListApi } from '@/apis/counter';

export interface IState {
  value: number;
  list: Array<any>;
}
const initialState: IState = {
  value: 0,
  list: [],
};
// thunk函数允许执行异步逻辑, 通常用于发出异步请求。
// createAsyncThunk 创建一个异步action，方法触发的时候会有三种状态：
// pending（进行中）、fulfilled（成功）、rejected（失败）
export const getMovieData = createAsyncThunk('movie/getMovie', async () => {
  const res = await getMovieListApi();
  return res;
});

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    add(state) {
      state.value += 1;
    },
    dec(state) {
      state.value -= 1;
    },
    setValue(state, action) {
      state.value = action.payload;
    },
  },
  // extraReducers 字段让 slice 处理在别处定义的 actions，
  // 包括由 createAsyncThunk 或其他slice生成的actions。
  extraReducers(builder) {
    builder
      // .addCase(getMovieData.pending, (state) => {
      //   console.log('🚀 ~ 进行中！');
      // })
      .addCase(getMovieData.fulfilled, (state, { payload }) => {
        console.log('🚀 ~ fulfilled', payload);
        state.list = payload.data.list;
        // console.log('state.list', state.list);
      })
      .addCase(getMovieData.rejected, (state, err) => {
        // console.log('🚀 ~ rejected', err);
      });
  },
});

export const { add, dec, setValue } = counterSlice.actions;

export default counterSlice.reducer;
