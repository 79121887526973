/* eslint-disable camelcase */
import request from '@/utils/request';

interface Test {
  list: any;
}

export const getMovieListApi = () =>
  request<Test>({
    method: 'get',
    url: '/search/recommend/list',
    params: {
      channel_id: 1,
      data_type: 1,
      mode: 24,
      page_id: 1,
      ret_num: 48,
    },
  }).then((res) => res);
